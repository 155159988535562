<template>
  <div id="loginForm" class="fxt-content" style="max-height:100vh">
    <div class="text-center mb-5 mb-lg-10">
      <h3 class="mt-5 ml-3">
        {{ (computedLoginType == 1) ? $t("LOGIN.FORM.TITLE") : $t(`LOGIN.FORM.THANK_YOU`) }}
      </h3>
      <p class="text-muted font-weight-bold font-size-h6">
        {{ $t(`LOGIN.FORM.MESSAGE_${computedLoginType}`) }}
      </p>
    </div>

    <form
      v-if="computedLoginType == 1"
      class="form"
    >

      <!--start::Error Handling-->
      <ErrorBox :errors="authenticationErrors" @clear_errors="clearErrors">
      </ErrorBox>
      <!--end::Error Handling-->

      <!--begin::Username-->
      <InputGeneric
        id="input-email"
        class="mb-7"
        :model="$v.form.email"
        alignLabel="left"
        :placeholder="$t('FORMS.INPUTS.EMAIL')"
        :showInline="false"
        :submissionStates="submissionStates"
        :label="$t('FORMS.INPUTS.EMAIL')"
        :error="$t('FORMS.ERROR.EMAIL')"
        type="email"
      >
      </InputGeneric>
      <!--end::Username-->

      <!--begin::Password-->
      <InputGenericPassword
        id="input-email"
        :model="$v.form.password"
        alignLabel="left"
        :placeholder="$t('FORMS.INPUTS.PASSWORD')"
        :showInline="false"
        :submissionStates="submissionStates"
        :label="$t('FORMS.INPUTS.PASSWORD')"
        :error="$t('FORMS.ERROR.PASSWORD')"
      >
      </InputGenericPassword>
      <!--end::Password-->

      <!--begin::Actions-->
      <div
        class="form-group d-flex flex-wrap justify-content-end align-items-center mb-0"
      >
        <router-link
          tag="a"
          :to="{ name: 'request_reset', params: { origin: 'login' } }"
          class="text-dark-50 text-hover-primary my-3 mr-2"
        >
          {{ $t('LOGIN.FORM.FORGOT') }}
        </router-link>
      </div>
      <div class="form-group d-flex flex-wrap justify-content-center align-items-center mt-10">
        <button
          class="btn btn-lg btn-primary w-75"
          :disabled="submissionStates.submitting === true"
          type="button"
          @click="onSubmit"
        >
          {{ $t('LOGIN.FORM.SIGN_IN') }}
        </button>
      </div>
      <!--end::Actions-->
    </form>

    <div v-else class="text-center">
      <b-spinner
        v-if="urlValidationState == null"
        variant="primary"
        label="Spinning"
        class="my-3"
      >
      </b-spinner>

      <div
        v-if="urlValidationState == 2"
        class="alert alert-custom alert-light-danger show mb-5`"
        role="alert"
      >
        <div class="alert-text">
          {{ $t(`LOGIN.FORM.URL_VALIDATION_ERROR`) }}
        </div>
      </div>

    </div>


  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { LOGIN, CLEAR, VERIFY_REGISTER_URL } from "@/modules/together-authentication/store/auth.module";

import { validationMixin } from "vuelidate";
import { email, minLength, required } from "vuelidate/lib/validators";

import ErrorBox from "@/view/components/ErrorBox";
import InputGeneric from "@/view/components/form-inputs/GenericInput";
import InputGenericPassword from "@/modules/together-helpers/components/generic-inputs/GenericPasswordInput";

export default {
  mixins: [validationMixin],
  
  name: "login2",

  props: {
    domainType: { type: Number, required: true },
    subDomain: { type: String, required: true },
    activityRedirect: { type: Number, required: false, default: null }
  },

  data() {
    return {
      error: {
        active: false,
        message: ""
      },
      form: {
        email: "",
        password: ""
      },
      loadingStates: {
        loggingIn: false
      },
      submissionStates: {
        submitting: false
      },
      showIntroduction: 1,
      showTandCs: 0,
      welcomeTab: 1,
      urlValidationState: null
    };
  },

  validations: {
    form: {
      email: {
        required,
        email
      },
      password: {
        required,
        minLength: minLength(3)
      }
    }
  },

  components: {
    ErrorBox,
    InputGeneric,
    InputGenericPassword
  },

  mounted() {
    this.$store.dispatch(CLEAR);
    this.cleanup();
  },

  methods: {
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    resetForm() {
      this.form = {
        email: null,
        password: null
      };
    },
    validateUrl: function() {
      this.$store.dispatch(VERIFY_REGISTER_URL, {
        key: this.$route.params.key,
        ps: this.$route.params.ps,
        pid: this.$route.params.pid,
        guid: this.$route.params.guid,
        variables: this.$route.params.variables
      })
      .then((res) => {
        const self = this;
        if (res.action != undefined) {
          setTimeout(function() {
            if (res.action == "register_redirect") {
              self.$router.push({ name: 'register', params: { token: res.token } });
            } else if (res.action == "input_redirect") {
              if (process.env.VUE_APP_SUBMODULE_IDENTIFIER == "ADMIN") {
                window.location.href = `${res.link}/#/activities`;
              } else {
                self.$router.push({ name: 'activities' });
              }
            }
          }, 2000)          
        } else {
          this.urlValidationState = 2;
        }
      })
      .catch(() => {
        this.urlValidationState = 2;
      });
    },
    onSubmit() {
      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        return;
      }

      let payload = {
        username: this.$v.form.email.$model,
        password: this.$v.form.password.$model,
        sub_domain: this.subDomain,
        node: process.env.VUE_APP_SUBMODULE_IDENTIFIER,
        grant_type: 'password',
        client_id: process.env.VUE_APP_PASSPORT_ID,
        client_secret: process.env.VUE_APP_PASSPORT_SECRET
      };

      if (this.domainType == 3 || this.domainType == 4) {
        payload.project_id = this.projectId
      };

      this.$store.dispatch(CLEAR);
      this.submissionStates.submitting = true;

      this.$store
        .dispatch(LOGIN, payload)
        .then((res) => {
          this.redirect(res.data.data.redirect);
        })
        .catch(() => {
          this.form.password = "";
        })
        .finally(() => {
          this.submissionStates.submitting = false;
          this.$v.form.$reset();
        });
    },
    clearErrors: function() {
      this.$store.dispatch(CLEAR);
    },
    redirect: function(link) {
      if (this.activityRedirect != null) {
        this.$router.push({ name: 'view_activity', params: { id: this.activityRedirect.activity, responseId: this.activityRedirect.response }});
      } else {
        window.location.href = link;
      }
    },
    cleanup: function() {
      this.clearErrors();
      this.form.email = "";
      this.form.password = "";
      this.urlValidationState = null;
      this.loadingStates.loggingIn = false;
    }
  },

  watch: {
    computedLoginType: {
      handler(newLoginType) {
        this.cleanup();
        if (newLoginType > 1) {
          const self = this;
          setTimeout(function() {
            self.validateUrl();
          }, 2000)
        }
      },
      immediate: true
    }
  },

  computed: {
    ...mapGetters([
      "isAuthenticated",
      "projectBrandList",
      "authenticationErrors"
    ]),

    isProjectDomain: function() {
      if (this.subDomain.indexOf("-") >= 0) {
        return true;
      } else {
        return false;
      }
    },

    isAdminDomain: function() {
      if (this.subDomain == "admin") {
        return true;
      } else {
        return false;
      }
    },

    projectId: function() {
      if (this.projectBrandList.length > 0) {
        return this.projectBrandList[0].project_id;
      } else {
        return null;
      }
    },

    hasTerms: function() {
      let configEntry = this.projectBrandList.filter(function(brand) {
        return brand.key == 12;
      })[0];

      if (configEntry == undefined || configEntry.value == null) {
        return false;
      } else {
        return true;
      }
    },

    calculatedShowIntroduction: function() {
      if (this.isProjectDomain == false) {
        return false;
      } else {
        if (this.showIntroduction) {
          return true;
        } else {
          return false;
        }
      }
    },

    calculatedClassList: function() {
      let classList = "";

      if (this.error.active) {
        classList = classList + "bordered-card-danger";
      } else {
        classList = classList + "bordered-card-primary";
      }

      let columnsFWIntro = " col-xs-12 col-sm-12 col-md-6 col-xxl-4";
      let columnsFWLogin = " col-xs-12 col-sm-12 col-md-6 col-xxl-3";

      let columnsSBIntro = " col-xs-12 col-sm-12 col-md-6 col-xxl-6";
      let columnsSBLogin = " col-xs-12 col=sm-12 col-md-6 col-xxl-5";

      if (this.isProjectDomain) {
        if (this.projectBrand[1].value == "1" || this.projectBrand[2].project_asset.length == 0)
        {
          classList = classList + ((this.calculatedShowIntroduction) ? columnsFWIntro : columnsFWLogin);
        }
        else {
          classList = classList + ((this.calculatedShowIntroduction) ? columnsSBIntro : columnsSBLogin);
        }
      } else {
        classList = classList + columnsFWLogin;
      }

      return classList;
    },

    computedRedirect: function() {
      if (process.env.VUE_APP_SUBMODULE_IDENTIFIER == "ADMIN") {
        return "view_clients";
      } else if (process.env.VUE_APP_SUBMODULE_IDENTIFIER == "INPUT") {
        return "view_activities";
      } else {
        return "error";
      }
    },

    computedLoginType: function() {
      if (
        this.$route.params.key != undefined &&
        this.$route.params.ps != undefined &&
        this.$route.params.pid != undefined &&
        this.$route.params.guid != undefined
      ) {
        return (this.$route.params.key == "anon") ? 2 : 3;
      } else {
        return 1
      }
    }
  }
};
</script>