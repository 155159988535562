<template>
  <b-alert
    v-if="Object.keys(errors).length > 0"
    class="mb-10" 
    show 
    variant="danger"
  >
    <button
      type="button"
      aria-label="Close"
      class="close"
      @click="clearErrors"
    >
      <div class="alert-close">
        <span aria-hidden="true">
          <i class="la la-close"></i>
        </span>
      </div>
    </button>
    <p class="pt-2"><strong>{{ errors.message }}</strong></p>
    <ul class="px-5">
      <template v-for="(item, i) in errors.errors">
        <li class="py-1" v-bind:key="i">
          {{ item[0] }}
        </li>
      </template>
    </ul>
  </b-alert>
</template>

<script>
export default {
  name: "ErrorBox",

  props: [
    'errors'
  ],

  methods: {
    clearErrors() {
      this.$emit('clear_errors')
    }
  }
};
</script>
