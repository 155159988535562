<template>
  <b-form-group
    :id="id + '-form-group'"
    :label="computedLabel"
    :label-for="id"
    :label-sr-only="!displayLabel"
    class="w-100 mb-0"
    :class="computedLabelAlignment"
    :label-cols-sm="computedLabelColsSM"
    :label-cols-lg="computedLabelColsLG"
  >
    <div class="input-group">
      <b-form-input
        :prepend="prepend"
        :append="append"
        :type="inputType"
        class="form-control form-control-solid h-auto px-6"
        :class="{ 'py-3': computedSlim, 'py-4': !computedSlim }"
        :id="id"
        :name="id"
        v-model="model.$model"
        :state="validateState()"
        :aria-describedby="'feedback-' + id"
        :disabled="submissionStates.submitting === true || computedDisabled"
        :placeholder="computedPlaceholder"
        :maxlength="computedMaxLength"
        @keyup.enter="emitEnter"
      ></b-form-input>
      <div class="input-group-append">
        <button
          v-if="inputType == 'password'"
          @click="showPassword"
          class="btn btn-secondary"
          type="button"
        >
          {{$t("OTHER.REGISTRATION.SHOW")}}
        </button>
        <button
          v-else
          @click="hidePassword"
          class="btn btn-secondary"
          type="button"
        >
          {{ $t("OTHER.REGISTRATION.HIDE") }}
        </button>
      </div>
      <b-form-invalid-feedback
        :id="'feedback-' + id"
        v-if="displayError && model.$anyError"
      >
        {{ error }}
      </b-form-invalid-feedback>
      <b-form-text v-if="helper != undefined" :id="'helper-' + id">
        {{ helper }}
      </b-form-text>
    </div>
  </b-form-group>
</template>

<script>
export default {
  name: "GenericInput",

  data() {
    return {
      inputType: "password",
    };
  },

  props: {
    id: { type: String, required: true },
    model: { type: Object, required: true },
    label: { type: String, required: false },
    alignLabel: { type: String, required: false },
    placeholder: { type: String, required: false },
    error: { type: String, required: false },
    showInline: { type: Boolean, required: true },
    submissionStates: { type: Object, required: true },
    helper: { type: String, required: false },
    disabled: { type: Boolean, required: false },
    maxLength: { type: String, required: false },
    slim: { type: Boolean, required: false },
    prepend: { type: String, required: false },
    append: { type: String, required: false },
  },

  methods: {
    validateState() {
      const { $dirty, $error } = this.model;
      return $dirty ? !$error : null;
    },
    emitEnter: function () {
      this.$emit("enter_pressed");
    },
    showPassword: function () {
      this.inputType = "text";
    },
    hidePassword: function () {
      this.inputType = "password";
    },
  },

  computed: {
    displayLabel: function () {
      if (this.label != undefined) {
        if (this.label.length > 0) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    },
    displayError: function () {
      if (this.error != undefined) {
        if (this.error.length > 0) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    },
    computedLabel: function () {
      if (this.model.$params.hasOwnProperty("required")) {
        return this.label + "*";
      } else {
        return this.label;
      }
    },
    computedPlaceholder: function () {
      if (this.model.$params.hasOwnProperty("required")) {
        if (this.model.$params.required.type != undefined) {
          if (this.model.$params.required.type == "requiredIf") {
            return this.placeholder;
          } else {
            if (this.model.required) {
              return this.placeholder + "*";
            } else {
              return this.placeholder;
            }
          }
        } else {
          return this.placeholder;
        }
      } else {
        return this.placeholder;
      }
    },
    computedLabelColsSM: function () {
      if (this.displayLabel) {
        if (this.showInline) {
          return 4;
        } else {
          return 0;
        }
      } else {
        return 0;
      }
    },
    computedLabelColsLG: function () {
      if (this.displayLabel) {
        if (this.showInline) {
          return 3;
        } else {
          return 0;
        }
      } else {
        return 0;
      }
    },
    computedLabelAlignment: function () {
      if (this.alignLabel != undefined) {
        if (this.alignLabel == "right") {
          return "text-right";
        } else if (this.alignLabel == "center") {
          return "text-center";
        } else {
          return "text-left";
        }
      } else {
        return "text-left";
      }
    },
    computedType: function () {
      if (this.type != undefined) {
        return this.type;
      } else {
        return "text";
      }
    },
    computedDisabled: function () {
      if (this.disabled != undefined) {
        return this.disabled;
      } else {
        return false;
      }
    },
    computedMaxLength: function () {
      if (this.maxLength != undefined) {
        return this.maxLength;
      } else {
        return false;
      }
    },
    computedSlim: function () {
      if (this.slim != undefined) {
        return this.slim;
      } else {
        return false;
      }
    },
  },
};
</script>
